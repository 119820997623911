@font-face {
    font-family: 'Open Sans','Nunito Sans','Lexend';
    src: url(assets/fonts/NunitoSans_10pt-Regular.ttf) format("truetype"), url(assets/fonts/NunitoSans_10pt-Regular.ttf) format("truetype"), url(assets/fonts/NunitoSans_10pt-Regular.ttf) format("truetype");
}

@font-face {
    font-family:'Nunito Sans';
   src: url(assets/fonts/NunitoSans_10pt-Regular.ttf) format("truetype");
}

@font-face {
    font-family: 'Lexend';
    src:  url(assets/fonts/NunitoSans_10pt-Regular.ttf) format("truetype");
}

body {
    margin: 0;
    padding: 0;
    // font-family: 'Figtree'!important;
    font-family: 'Open Sans' !important;

}

img{
    max-width: 100%;
}

button {
    border: none;
}

p{
    // font-family: 'Open Sans';
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0.2px;
    text-align: justify;
}

.theme-container {
    max-width: 1920px !important;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
}

.theme-container-1314{
    max-width: 1314px !important;
    margin: 0 auto;
    padding: 0 15px;
}

.theme-container-1120{
    max-width: 1120px !important;
    margin: 0 auto;
    padding: 0 15px;
}

.theme-container-1622{
    max-width: 1622px !important;
    margin: 0 auto;
    padding: 0 15px;
}

.theme-container-1429{
    max-width: 1429px !important;
    margin: 0 auto;
    padding: 0 15px;
}

.theme-container-1284{
    max-width: 1284px !important;
    margin: 0 auto;
    padding: 0 15px;
}

.theme-container-928{
    max-width: 928px !important;
    margin: 0 auto;
    padding: 0 15px;
}

.px-2 {
    padding: 0 20px !important;
}

.px-3 {
    padding: 0 30px !important;
}

.mb-0{
    margin-bottom: 0px !important;
}

.mb-40{
    margin-bottom: 40px !important;
}

.mb-5 {
    margin-bottom: 10px !important;
}

.mb-3 {
    margin-bottom: 20px;
}

.mb-2 {
    margin-bottom: 10px;
}

.mb-120 {
    margin-bottom: 120px !important;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.dashboard-title{
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: #484848;
    margin-bottom: 25px;
}

.section-title{
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 43px !important;
    letter-spacing: 0.2px !important;
    text-align: left;
}

.tab-content--bg{
    background: #F8F8F8;
    height: 824px;
    overflow: auto;
    overflow-x: hidden;
    padding: 20px;
}

.blue-bg{
    background: #EFF7FF;
    padding: 80px 0;
}

.blue-bg h2{
    color: #374151 !important;
    margin-bottom: 32px;
}

.blue-bg p{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-align: justify !important;
    color: #374151 !important;
}

.blue-bg p b{
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.2px;
    text-align: center;
    color: #1E5DAF;
}

.blue-btn{
  background: #1D71D8;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: #F9FAFB !important;
  padding: 12px 16px !important;
  text-decoration: none;
  border-radius: 10px;
  width:160px;
  height:48px;
  letter-spacing: 0.2px;
}

.black-btn{
    background: #1C1A1A;
    display: inline-block;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-align: center;
    padding: 20px 35px;
    color: #FFFFFF;
    text-decoration: none;
}

.black-border-btn{
    display: inline-block;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-align: center;
    padding: 20px 35px;
    color: #1C1A1A;
    border: 2px solid #1C1A1A;
    text-decoration: none;
}

.chat-height{
    height: 600px;
}

// ::ng-deep .cdk-overlay-container{
//     position: fixed !important;
//     left: 0 !important;
//     width: 100% !important;
//     top: 80px !important;
//     z-index: 999 !important;
// }

::ng-deep .cdk-overlay-container {
    position: fixed;
    left: 0;
    width: 100%;
    top: 80px;
    z-index: 999;
}

::ng-deep .mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background-color: #333333 !important;
}

::ng-deep .mdc-snackbar__label {
    color: white;
}

::ng-deep .mdc-snackbar__label {
    color: #ff4081;
}

.popup-body{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.popup-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.32);
    z-index: 1000;
}

.popup-box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.32);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-material{
    display: inline-block;
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 4px 15px 0px #55555540;
    padding: 60px 80px;
    width: 90%;
    max-width: calc(800px - 200px);
    max-height: calc(100% - 180px);
    overflow: auto;
}

.popup-material{
    position: relative;
}

.cencle-div{
    position: absolute;
    right: 50px;
    top: 30px;
    background-color: #656565;
    color: #FFFFFF;
    padding: 3px 11px;
    border-radius: 50%;
    font-size: 32px;
    cursor: pointer;
}

.mat-divider {
    border-top-color: rgb(0 0 0 / 47%) !important;
}

@media screen and (max-width: 700px) {
    .popup-material{
        padding: 40px 20px;
        // border-radius: 50px 0px 0px 50px;
        max-height: calc(100% - 30px);
    }

    .cencle-div {
        right: 10px;
        top: 10px;
    }
}

@media screen and (min-width: 960px) {
    .sm-text-right {
        text-align: right;
    }
}
